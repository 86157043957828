import { Ringba } from './Ringba.js'
import { HookRunner } from './HookRunner.js'
import { Hook } from './enums/HookEnum.js'
import { Action } from './enums/ActionEnum.js'
import { AudioManager } from './AudioManager.js'
import { Chat } from './Chat.js'
import { StepHistoryStore } from './StepHistoryStore.js'
import { MediaAlpha } from './MediaAlpha.js'
import SmartySDK from 'smartystreets-javascript-sdk'
import { Alpine, Livewire } from './../../vendor/livewire/livewire/dist/livewire.esm'
import AlpineSmartyAddress from './AlpineSmartyAddress.js'
import router from '@shaun/alpinejs-router'
import { livewire_hot_reload as livewireHotReload } from 'virtual:livewire-hot-reload'
import QvMask from './alpine/directives/mask'

const SmartyCore = SmartySDK.core
const Lookup = SmartySDK.usAutocompletePro.Lookup
const key = import.meta.env.VITE_SMARTY_LICENSE
const credentials = new SmartyCore.SharedCredentials(key)
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([ 'us-autocomplete-pro-cloud' ])
const client = clientBuilder.buildUsAutocompleteProClient()

Alpine.plugin(router)
Alpine.plugin(QvMask)
Alpine.data('smartyAddress', AlpineSmartyAddress)

Alpine.store('qv', {
    ringba: new Ringba(),
    hookRunner: new HookRunner(),
    mediaAlpha: new MediaAlpha(),
    metadata: window.__METADATA ?? {},
    modals: {},
    smartyClient: client,
    smartyLookup: Lookup,
    audioManager: new AudioManager(),
    stepHistory: new StepHistoryStore(),
    chat: new Chat(),
    webForm: {
        canSubmit: true,
    },

    /**
     * @param {string} url
     * @param {string} offset
     * @returns {void}
     */
    playWebFormAudio(url, offset) {

        if (this.stepHistory.hasAnsweredStep(offset)) {
            return
        }

        this.audioManager.autoplayStepAudio(url)

    },

    replaceTokens(string, tokens) {

        for (const token in tokens) {
            string = string.replace(new RegExp(`{${ token }}`, 'g'), String(tokens[ token ]))
        }

        return string

    },
    toDecimalAge(date) {

        /*
         * In order to better approximate the decimal age we use an average of 365.25 days to account for leap years.
         * (3 * 365 + 366) / 4 = 365.25 (31557600000 milliseconds)
         */
        const averageYearlyMilliseconds = 31557600000
        const age = (Date.now() - date.getTime()) / averageYearlyMilliseconds

        /*
         * Since we are rounding to two decimal places, we will lose a bit of precision.
         * The resulting age may be off by +/- 2 days. This is fine because the value calculated here is
         * not used to calculate the exact Date of Birth.
         */
        return parseFloat(age.toFixed(2))

    },
    isBlank(value) {

        if (value === undefined || value === null) {
            return true
        }

        if (Number.isNaN(value)) {
            return true
        }

        if (Array.isArray(value)) {
            return value.length === 0
        }

        if (typeof value === 'object') {
            return this.isBlank(Object.keys(value))
        }

        return value === '' || value === 'NaN' || value === '0'

    },

    isFilled(value) {
        return !this.isBlank(value)
    },

    runBeforeStepSubmissionHook(formData) {
        this.hookRunner.run(Hook.BeforeStepSubmission, formData, this.metadata)
    },

    runAfterEnterThankYouPageHook(metadata) {
        this.hookRunner.run(Hook.AfterEnterThankYouPage, metadata)
    },

    bindModalHandlers() {

        const modalLinks = Array.from(document.getElementsByClassName('modal-link'))

        for (const element of modalLinks) {

            element.removeEventListener('click', event => this.processModalEvent(event))
            element.addEventListener('click', event => this.processModalEvent(event))

        }

    },

    processModalEvent(event) {

        if (event.target instanceof HTMLElement) {

            const id = event.target.getAttribute('data-modal')

            if (id && Object.keys(this.modals).includes(id)) {

                Livewire.dispatch('openModal', {
                    component: 'modals.base-modal',
                    arguments: { content: this.modals[ id ] },
                })

            }

        }

    },

    setModals(modals) {
        this.modals = { ...modals }
    },

    startWebForm() {

        const components = Livewire.getByName('pages.landing-web-form-chat')

        if (this.isFilled(components)) {

            components[ 0 ].step = 1
            components[ 0 ].$parent.step = 1

            return true

        }

        return false

    },

    handleAction(action, ringbaPhoneNumber, callback = null) {

        switch (action?.type) {

        case Action.InternalLink:
            window.location.href = action.url
            callback?.call()

            return

        case Action.ThunderboltLink:
            window.location.href = action.url
            callback?.call()

            return

        case Action.ExternalLink:
            window.open(action.url, '_blank')
            callback?.call()

            return

        case Action.TriggerDialer:
            window.open(`tel:${ action.phoneNumber || ringbaPhoneNumber.number }`, '_self')
            callback?.call()

            return

        case Action.StartWebForm: {

            if (this.startWebForm()) {
                callback?.call()
            }

        }

        }

        return null

    },

})

livewireHotReload()
Livewire.start()
