import Mark from 'mark.js'

export default (name, prefillValue, searchValue) => ({
    fieldName: name,
    modelValue: prefillValue,
    search: searchValue,
    suggestions: [],
    showResults: false,
    selectedIndex: null,
    setSelectedIndex(index) {
        this.selectedIndex = index
    },
    resetSelectedIndex() {
        this.selectedIndex = null
    },
    selectNextIndex() {

        if (this.selectedIndex === null || this.selectedIndex === this.suggestions.length - 1) {
            this.selectedIndex = 0
        } else {
            this.selectedIndex++
        }

    },
    selectPreviousIndex() {

        if (this.selectedIndex === null || this.selectedIndex === 0) {
            this.selectedIndex = this.suggestions.length - 1
        } else {
            this.selectedIndex--
        }

    },
    async handleSearch() {

        if (this.search.length === 0) {

            this.modelValue = null
            this.suggestions = []

            return

        }

        try {

            const lookup = new this.$store.qv.smartyLookup(this.search)
            const response = await this.$store.qv.smartyClient.send(lookup)

            if (response?.result?.length) {
                this.suggestions = response.result.splice(0, 7)
            } else {
                this.modelValue = { address: this.search }
            }

        } catch (error) {

            this.suggestions = []
            this.modelValue = { address: this.search }

        }

    },
    itemTemplate(suggestion) {

        return [ suggestion.streetLine, suggestion.city, suggestion.state, suggestion.zipcode ]
            .filter(Boolean)
            .join(', ')

    },
    highlightResults() {

        const instance = new Mark(this.$refs.results)

        instance.mark(this.search)

    },
    handleEnterKey() {

        if (this.selectedIndex === null) {
            return
        }

        this.setValue(this.suggestions[ this.selectedIndex ])

    },
    handleEscapeKey() {
        this.$refs.results.blur()
    },
    setValue(suggestion) {

        this.search = this.itemTemplate(suggestion)

        this.modelValue = {
            address: suggestion.streetLine,
            city: suggestion.city,
            state: suggestion.state,
            zip: suggestion.zipcode,
        }

        this.$nextTick(() => this.showResults = false)

    },
    async init() {

        this.$watch('search', () => this.handleSearch())
        this.$watch('modelValue', value => this.setFormData(this.fieldName, value))
        this.$watch('suggestions', () => this.highlightResults())
        this.$watch('showResults', show => !show ? null : this.resetSelectedIndex())

        if (this.modelValue) {
            this.setFormData(this.fieldName, this.modelValue)
        }

    },
})
