import { ChatRole } from './enums/ChatRoleEnum'
import { ApiCode } from './enums/ApiCodeEnum'
import { Alpine } from './../../vendor/livewire/livewire/dist/livewire.esm'
import { beforeChatConversationUpdated, afterChatConversationUpdated } from './DomainHooks'

export class Chat {

    submitCallback = null
    pollCallback = null

    formData = {
        content: '',
    }

    loading = true
    submitting = false
    messages = []
    chatCompleted = false
    pollIntervalId = null
    error = null
    pollCounter = 0
    slowResponseTimeThresholdSeconds = 20

    get lastMessageId() {

        if (this.messages.length === 0) {
            return null
        }

        return this.messages[ this.messages.length - 1 ].id

    }

    submitUsing(callback) {
        this.submitCallback = callback
    }

    pollUsing(callback) {
        this.pollCallback = callback
    }

    randomKey() {
        return Math.floor(Math.random() * 1000000)
    }

    pollForMessages(fromMessageId) {

        clearInterval(this.pollIntervalId)

        this.pollIntervalId = setInterval(async() => {

            const response = await this.pollCallback(fromMessageId)

            if (response.success === true) {

                // If there is no content, we want to keep polling
                if (response.code === ApiCode.NoContent) {
                    this.pollCounter++
                } else {

                    const newMessages = response.data.messages
                    const latestAnswer = response.data.latest_visitor_answer_data

                    afterChatConversationUpdated(this.formData, latestAnswer)
                    this.handleIncomingMessages(newMessages)

                    if (response.data.completed_at) {
                        this.chatCompleted = true
                    }

                    // We can stop polling now and reset everything for the next message
                    clearInterval(this.pollIntervalId)
                    this.pollCounter = 0
                    this.loading = false
                    this.error = null

                }

            }

            if (this.pollCounter >= this.slowResponseTimeThresholdSeconds) {
                this.error = 'Oops, it\'s taking us a little longer to respond...'
            }

        }, 1000)

    }

    randomDelay() {

        const milliseconds = Math.floor(Math.random() * (750 - 500 + 1) + 500)

        return new Promise(resolve => setTimeout(resolve, milliseconds))

    }

    async submitForm() {

        const store = Alpine.store('qv')

        this.submitting = true
        beforeChatConversationUpdated(this.formData, store.metadata)

        const message = {
            id: this.messages.length + 1,
            key: this.randomKey(),
            role: ChatRole.User,
            hasError: false,
            ...this.formData,
        }

        this.messages.push(message)

        const response = await this.submitCallback(this.formData)

        if (response.success === true) {

            message.id = response.data.message.id
            this.error = null
            await this.randomDelay()
            this.loading = true
            this.pollForMessages(this.lastMessageId)

        } else {

            this.error = 'Looks like we are having some trouble right now. Please try again in a few seconds.'

            setTimeout(() => {

                setLoading(false)
                this.error = null

            }, 4000)

        }

        this.submitting = false

    }

    setFormData(value) {
        this.formData.content = value
    }

    async showWelcomeMessage(currentStep, welcomeMessage) {

        if (this.messages.length) {
            return
        }

        await this.randomDelay()

        this.messages.push({
            id: this.messages.length + 1,
            key: this.randomKey(),
            role: ChatRole.Assistant,
            hasError: false,
            content: welcomeMessage,
        })

        await this.randomDelay()

        this.messages.push({
            id: this.messages.length + 1,
            key: this.randomKey(),
            role: 'assistant',
            hasError: false,
            content: currentStep.content.headline,
            step: currentStep,
        })

        this.loading = false

    }

    handleIncomingMessages(newMessages) {

        for (const message of newMessages) {

            message.key = this.randomKey()
            this.messages.push(message)

        }

    }

    isValidDateFormat(dateString) {

        const dateRegex = /^(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[01])(19|20)\d{2}$/

        return dateRegex.test(dateString)

    }

    convertYYYYMMDDtoDDMMYYYYwithMasks(dateString) {

        const parts = dateString.split('/')

        if (parts.length === 3) {

            const y1 = parts[ 0 ].padStart(2, '0')
            const y2 = parts[ 1 ].padStart(2, '0')
            const monthday = parts[ 2 ].padStart(4, '0')
            const month = monthday.substring(0, 2)
            const day = monthday.substring(2, 4)

            return `${ month }/${ day }/${ y1 }${ y2 }`

        }

        return dateString

    }

}
